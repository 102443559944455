import { AnimatePresence } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/auth";
import { useBalance } from "../../hooks/balance";
import { useClient } from "../../hooks/client";
import { cn } from "../../utils/cn";
import { currencyFormat } from "../../utils/currencyFormat";
import { CloseIcon } from "../icons/close-icon";
import { SidebarIcon } from "../icons/sidebar-icon";
import { OptionSidebar } from "../optionSidebar";

import { Service } from "../service";
import {
  Arrowicon,
  BalanceInfoAccount,
  ChargeIcon,
  DepositIcon,
  HomeIcon,
  InfoAccount,
  OptionsWrapper,
  PixIcon,
  BillIcon,
  ReceiptIcon,
  SideFix,
  UserProfileIcon,
  TransferMoneyIcon,
  VisionIcon,
} from "./styles";

interface Props {
  isActive: boolean
  handleSidebarMobileToggle: () => void
}

export const Sidebar: React.FC<Props> = ({ isActive, handleSidebarMobileToggle }) => {
  const route = useLocation();
  const { account } = useAuth();
  const { client } = useClient();
  const { isBalanceVisible, setIsBalanceVisible } = useBalance()

  const [bankServicesIsOpen, setBankServicesIsOpen] = useState(true);
  const [myAccountIsOpen, setMyAccountIsOpen] = useState(true);

  const handleBankServiceToggle = () => {
    setBankServicesIsOpen(state => !state)
  }

  const handleMyAccountoggle = () => {
    setMyAccountIsOpen(state => !state)
  }

  const handleBalanceAccounttoggle = () => {
    setIsBalanceVisible(state => !state)
  }


  const [isMobile, setIsMobile] = useState(window.innerWidth < 640)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640)
    }

    window.addEventListener('resize', handleResize)

    if (window.innerWidth >= 640) {
      setIsMobile(false)
    }

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const [isSidebarActive, setIsSidebarActive] = useState(isActive)

  useEffect(() => {
    setIsSidebarActive(isActive)
  }, [isActive])

  return (
    <div
      className={cn(
        'sidebar-scrollbar fixed top-0 z-50 flex h-full w-64 flex-col overflow-y-auto bg-[#142249] pb-8 transition-all duration-200 sidebar:block',
        !isMobile && 'left-0',
        !isSidebarActive && isMobile && 'left-[-256px]',
      )}
      style={{
        gridArea: 'sidebar',
      }}
    >
      <button
        onClick={handleSidebarMobileToggle}
        className="flex h-20 w-full items-center bg-gray-bg px-6 py-9 transition-all duration-[2000ms]"
      >
        {isMobile && (
          isSidebarActive ? (
            <SidebarIcon className="text-primary w-4 h-4" />
          ) : (
            <CloseIcon className="h-4 w-4 text-primary" />
          )
        )}

        <div style={{ marginLeft: '8px' }}>
          <img src={client.whiteLogo.image} alt={client.name} style={{
            width: client.whiteLogo.width + 40,
            height: client.whiteLogo.height + 12,
            marginLeft: '24px',
          }} />
        </div>
      </button>
      <InfoAccount>
        <p>Saldo na conta</p>
        <BalanceInfoAccount>
          <p>{isBalanceVisible ? currencyFormat(account.balance) : 'R$ ***'}</p>
          <button onClick={handleBalanceAccounttoggle}>
            <VisionIcon />
          </button>
        </BalanceInfoAccount>
      </InfoAccount>
      <SideFix>

        <OptionSidebar
          isSelected={route.pathname === '/'}
          linkTo={'/'}
          title={'Inicio'}
          Icon={HomeIcon} />

        <OptionSidebar
          isSelected={route.pathname === '/extract'} linkTo={'/extract'}
          title={'Extrato'}
          Icon={ReceiptIcon} />

        {/* <OptionSidebar
                isSelected={route.pathname === '/extract'}     linkTo={'/extract'}
                title={'Comprovantes'}
                Icon={BankMoneyIcon} /> */}

      </SideFix>
      <Service handleOption={handleBankServiceToggle} isOpen={bankServicesIsOpen} Icon={Arrowicon} title="Serviços Bancários" />
      <AnimatePresence>
        {
          bankServicesIsOpen &&
          <OptionsWrapper>

            <OptionSidebar
              isSelected={route.pathname.startsWith('/deposit')}
              linkTo={'/deposit'}
              title={'Depositar'}
              Icon={DepositIcon} />

            <OptionSidebar
              isSelected={route.pathname === '/charge'}
              linkTo={'/charge'}
              title={'Cobrar'}
              Icon={ChargeIcon} />

            <OptionSidebar
              isSelected={route.pathname.startsWith('/pix')}
              linkTo={'/pix'}
              title={'Pix'}
              Icon={PixIcon} />

            <OptionSidebar
              isSelected={route.pathname.startsWith('/paybills')}
              linkTo={'/paybills'}
              title={'Pagar Contas'}
              Icon={BillIcon}
            />

            <OptionSidebar
              isSelected={route.pathname.startsWith('/transfer')}
              linkTo={'/transfer'}
              title={'Transferir TED/DOC'}
              Icon={TransferMoneyIcon} />

          </OptionsWrapper>
        }
      </AnimatePresence>
      <Service handleOption={handleMyAccountoggle} isOpen={myAccountIsOpen} Icon={Arrowicon} title="Minha Conta" />
      <AnimatePresence>
        {
          myAccountIsOpen &&
          <OptionsWrapper>

            {/* <OptionSidebar
                            isSelected={route.pathname === '/tax'}
                            linkTo={'/tax'}
                            title={'Tarifas'}
                            Icon={ChargeIcon} />



                            <OptionSidebar
                            isSelected={route.pathname === '/1'}
                            linkTo={'/1'}
                            title={'Ajuda'}
                            Icon={BillIcon} /> */}

            {/* <OptionSidebarButton
                            handleFuction={handleOpenReferenceModal}
                            title={'Convide amigos'}
                            Icon={ShareIcon} /> */}



            {/* {client.host === 'novo.nobebank.com.br' && (
                              <OptionSidebar
                                isSelected={route.pathname === '/signout'}
                                linkTo={'#'}
                                title={'Cobranças anteriores'}
                                onClick={() => {
                                  window.location.href = 'https://internet-banking-wb.vercel.app';
                                }}
                                Icon={TransferMoneyIcon} />
                            )} */}

            <OptionSidebar
              isSelected={route.pathname === '/myaccount'}
              linkTo={'/myaccount'}
              title={'Minha Conta'}
              Icon={UserProfileIcon} />

            <OptionSidebar
              isSelected={route.pathname === '/signout'}
              linkTo={'/signout'}
              title={'Sair'}
              Icon={TransferMoneyIcon} />

          </OptionsWrapper>
        }
      </AnimatePresence>
    </div>
  )
}

