import { FormEvent, Fragment, SetStateAction, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "../components/button";
import { InputInsertValue } from "../components/input-value";

import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon, ExclamationIcon } from '@heroicons/react/outline';

import { format } from "date-fns";
import brasilLocale from 'date-fns/locale/pt-BR';
import { PasswordInput } from "../components/passwordInput";
import { ReturnBack } from "../components/return-back";
import { useAuth } from "../hooks/auth";
import { api, parseError } from "../services/api";
import { documentFormatted } from "../utils/documentFormatted";
import { useNotification } from "../hooks/notification";
import { documentFormat } from "../utils/document-format";
import { dateFormatWithHours } from "../utils/date-format-with-hours";
import { formatName } from "../utils/format-name";
import { addHyphenBeforePenultimateChar } from "../utils/add-hyphen-before-penultimate-char";
import { IPixData } from "./pixTransfer";


interface StateProps {
  key: string;
  keyType: string;
  pixData: IPixData;
}

export interface PixProps {
  id: string
  credit: boolean
  amount: number
  description: string
  rateValue: number
  consignorName: string
  consignorDocument: string
  consignorBankNumber: string
  consignorBankBranch: string
  consignorBankAccount: string
  consignorBankAccountDigit: string
  beneficiaryName: string
  beneficiaryDocument: string
  beneficiaryBankNumber: string
  beneficiaryBankBranch: string
  beneficiaryBankAccount: string
  beneficiaryBankAccountDigit: string
  status: string
  date: string
}


export function PixConfirm() {
  const { setOpenNotification } = useNotification();

  const navigate = useNavigate();
  const location = useLocation();
  const { account } = useAuth();

  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [maskedValue, setMaskedValue] = useState('');
  const [error, setError] = useState('');
  const [pin, setPin] = useState('');

  const [checked, setChecked] = useState(false);

  const state = location.state as StateProps;

  const handleCancelButton = () => {
    navigate("/pix");
  };

  function handleChangeContact(e: any) {
    setChecked(e.target.checked)
  }


  const handleConfirmButton = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);


    let pixKeyType = ''
    if (state.keyType === "Celular") {
      pixKeyType = 'phone'
    } else if (state.keyType === "CPF/CNPJ") {
      if (state.key.replace(/[^\d]/g, '').length === 11) {
        pixKeyType = 'cpf'
      } else {
        pixKeyType = 'cnpj'
      }
    } else if (state.keyType === "E-mail") {
      pixKeyType = 'email'
    } else if (state.keyType === "Chave aleatória") {
      pixKeyType = 'evp'
    } else {
      setOpenNotification({
        type: 'error',
        title: 'Chave inválida',
        notification: 'Escolha um tipo de chave válido!'
      });
    }

    try {
      await api.post("/transfers", {
        key: state.key,
        amount: value,
        pin,
        save: checked,
        check_payer: state.pixData.beneficiary_document,
        type: pixKeyType,
        beneficiary: {
          document: state.pixData.beneficiary_document,
          name: state.pixData.name,
          bank: state.pixData.bank_code,
          branch: state.pixData.bank_agency,
          account: state.pixData.bank_account,
          accountDigit: '0',
        }
      })

      setLoading(false);
      setOpen(true);
    } catch (err) {
      setLoading(false);
      const error = parseError(err);
      setError(error.friend);
    }
  };

  const handlePasswordChange = (event: { target: { value: SetStateAction<string>; }; }) => {
    setPin(event.target.value);
  }

  const handleChange = (event: FormEvent, value: number, maskedValue: string) => {
    event.preventDefault();
    setValue(value);
    setMaskedValue(maskedValue);
  };

  function onCloseModal() {
    setOpen(false);
    navigate("/extract");
  }

  useEffect(() => {
    if (state === null) {
      navigate("/pix");
    }
  }, []);


  return (
    <div className="h-screen p-8 bg-[var(--background)]">
      <ReturnBack title="Confirme os dados do pagamento" />

      <form onSubmit={handleConfirmButton}>
        <div className="w-[480px] bg-confirm-bg p-8 flex flex-col shadow rounded">
          <div className="flex w-full items-center justify-between pb-4">
            <span className="font-semibold text-tx-primary">
              <span className="text-primary font-bold">Nome:</span> {state.pixData.name}
            </span>
            <span className="text-primary font-bold">
              {dateFormatWithHours(new Date())}
            </span>
          </div>

          <div className="flex w-full items-center gap-4 pb-4">
            <span className="text-primary font-bold">CPF/CNPJ</span>
            <span className="text-text-gray">
              {documentFormat(state.pixData.beneficiary_document)}
            </span>
          </div>

          <div className="flex w-full items-center gap-4 pb-4">
            <span className="text-primary font-bold">Banco</span>
            <span className="text-text-gray">
              {formatName(state.pixData.bank, ['S.A.'])}
            </span>
          </div>

          <div className="flex flex-wrap sm:flex-nowrap">
            <div className="flex w-full items-center gap-4 pb-4">
              <span className="text-primary font-bold">Agência</span>
              <span className="text-text-gray">{state.pixData.bank_agency}</span>
            </div>

            <div className="flex w-full items-center gap-4 pb-4">
              <span className="text-primary font-bold">Conta</span>
              <span className="text-text-gray">
                {addHyphenBeforePenultimateChar(
                  String(state.pixData?.bank_account).slice(-9),
                )}
              </span>
            </div>
          </div>

          <InputInsertValue onChange={(event, value, masked) => handleChange(event, value, masked)} className="text-base" />


          <div className="mt-10 rounded max-w-[480px] bg-white p-8 shadow rounded-b border-t border-gray-200">
            <p className="font-[var(--semiBold)] text-[var(--gray-dark)] mb-4">Senha</p>
            <PasswordInput borderRadius="4px" borderColor="#E3E3E3" placeholder="****" onChange={handlePasswordChange} />
          </div>

          <div className="mt-10 rounded max-w-[480px] bg-white p-8 shadow rounded-b border-t border-gray-200">
            <fieldset>
              <legend className="sr-only">Notifications</legend>
              <div className="space-y-5">
                <div className="relative flex items-start">
                  <div className="flex h-6 items-center">
                    <input
                      id="comments"
                      aria-describedby="comments-description"
                      name="comments"
                      type="checkbox"
                      onChange={handleChangeContact}
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label htmlFor="comments" className="font-medium text-gray-900">
                      Salvar favorecido
                    </label>
                    <p id="comments-description" className="text-gray-500">
                      Salve o favorecido para transferências futuras
                    </p>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>

        <div className="flex items-center gap-6 mt-6 w-[480px]">
          <Button
            handleFunction={handleCancelButton}
            color="#e3e3e3"
            title="Cancelar"
          />
          <Button type="submit" isLoading={loading} title="Transferir" />
        </div>
      </form>

      {!!error && (
        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mt-5 w-[480px]">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <p className="text-sm text-yellow-700">
                {error}
              </p>
            </div>
          </div>
        </div>
      )}

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onCloseModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                  <div>
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                      <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        Transferência Pix realizada
                        <br />

                        {maskedValue}
                      </Dialog.Title>
                      <div className="mt-2 text-left">
                        <p className="text-sm text-gray-500">
                          <h2 className="mb-5 mt-5 text-lg">Dados da conta debitada</h2>
                          <div>
                            <h2>Nome</h2>
                            <h3>{account.name}</h3>
                          </div>

                          <div className="mt-5">
                            <h2>Documento</h2>
                            <h3>{documentFormatted(account.document)}</h3>
                          </div>

                          <div className="mt-5 flex">
                            <div>
                              <h2>Agência</h2>
                              <h3>{account.branch}</h3></div>
                            <div className="ml-28">
                              <h2>Conta</h2>
                              <h3>{account.account}-{account.accountDigit}</h3></div>
                          </div>

                          <h2 className="mb-5 mt-5 text-lg">Dados do favorecido</h2>
                          <div className="mt-5 flex">
                            <div className="mr-20">
                              <h2>Nome:</h2>
                              <h3>{documentFormatted(state.pixData.name)}</h3>
                            </div>
                          </div>

                          <div className="mt-5 flex">
                            <div className="mr-20">
                              <h2>Documento:</h2>
                              <h3>{documentFormatted(state.pixData.beneficiary_document)}</h3>
                            </div>

                            <div>
                              <h2>Banco:</h2>
                              <h3>{formatName(state.pixData.bank, ['S.A.'])}</h3>
                            </div>
                          </div>

                          <div className="mt-5 flex">
                            <div className="mr-[118px]">
                              <h2>Agência:</h2>
                              <h3>{state.pixData.bank_agency}</h3>
                            </div>

                            <div>
                              <h2>Conta</h2>
                              <h3>{addHyphenBeforePenultimateChar(
                                String(state.pixData?.bank_account).slice(-9),
                              )}</h3>
                            </div>
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                      onClick={() => onCloseModal()}
                    >
                      Ir para o extrato
                    </button>

                    <button
                      type="button"
                      className=" mt-4 inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
                    >
                      Comprovante disponível no extrato
                    </button>

                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};








